import { useRef, useEffect } from "react";
import { Canvas, Rect, FabricImage, FabricText, TextProps } from "fabric";

const FPS = 20;

class FabricTypewriter extends FabricText {
  textSrc: string = "";
  duration: number = 1000;
  startTime: number = 0;
  started: boolean = false;
  finished: boolean = false;
  onComplete = () => {};

  constructor(text: string, options?: Partial<TextProps>) {
    super("", options);
    this.textSrc = text;
    this.doAnimateTypewriter = this.doAnimateTypewriter.bind(this);
  }

  animateTypeWriter(text: string, options: { duration: number, onComplete: any}) {
    this.set("text", "");
    this.textSrc = text + " ";
    this.duration = options.duration;
    this.onComplete = options.onComplete;
    this.canvas?.renderAll();
    requestAnimationFrame(this.doAnimateTypewriter);
  }

  doAnimateTypewriter(currentTime: number) {
    if (!this.started) {
      this.startTime = currentTime;
      this.started = true;
    } else if (!this.finished) {
      const proportion = (currentTime - this.startTime) / this.duration;
      if (proportion < 1.0) {
        this.set("text", (this.textSrc).substring(0, Math.floor(proportion * this.textSrc.length)));
        this.canvas?.renderAll();
      } else {
        this.set("text", this.textSrc);
        this.canvas?.renderAll();
        this.finished = true;
      }
    } else {
      this.onComplete();
      return;
    }
    requestAnimationFrame(this.doAnimateTypewriter)
  }
}


export default function ScreenAnimation(props: any) {
  let canvasRef = useRef<HTMLCanvasElement | null>(null);
  let c = useRef<Canvas | null>(null);

  useEffect(() => {
    if (!canvasRef.current || c.current) return;
    c.current = new Canvas(canvasRef.current);
  }, []);

  const startAnimate = async (record: boolean) => {
    if (!c.current || !canvasRef.current) return;
    
    const stream = canvasRef.current.captureStream(FPS);
    let mediaRecorder: MediaRecorder | null = null;
    if (record) {
      mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm; codes=vp9'});
      let recordedChunks: any[] = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
            recordedChunks.push(event.data);
        }
      };
      mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunks, { type: 'video/webm' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'animation.webm';
          a.click();
          recordedChunks = [];
      };
    }

    let image = await FabricImage.fromURL("data/screentest.png");
    c.current.add(image);
    
    if (record && mediaRecorder) mediaRecorder.start();

    let rect = new Rect({
      left: 50,
      top: 100,
      fill: 'red',
      width: 60,
      height: 60
    });
    c.current.add(rect); 

    let textSrc = "ทดสอบการพิมพ์"
    let text = new FabricTypewriter("ท", {left: 100, top: 200, strokeWidth: 2});
    c.current.add(text)

    let renderAll = c.current.renderAll.bind(c.current);

    rect.animate({left: 200}, {
      onChange: renderAll, 
      duration: 2000,
      onComplete: () => { 
        text.animateTypeWriter(textSrc, {
          duration: 2000, 
          onComplete: () => {
            if (record && mediaRecorder) mediaRecorder.stop();
          }
        })
      }
    });
  };

  return(
    <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", border: "solid black 1px"}}>
      <div>
        <button
          onClick={() => startAnimate(false)}>
          Render
        </button>
        <button
          onClick={() => startAnimate(true)}>
          Render and Record
        </button>
      </div>
      <canvas ref={canvasRef}
        width="1000" height="500"
        style={{ border: "solid red 1px"}} 
      />
    </div>
  )
}
