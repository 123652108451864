import { useEffect, useRef, useState } from "react";
import { getStepSection } from "./AlgorithmDiagram";
import { DiagramGroup, StepSection } from "./TypeDef";
import { Icon } from "semantic-ui-react";
// import { DownloadDataAsJsonFile } from "ismor-lib/utils/File";
import XDDiagramWorker from "./XDDiagram.worker";

// import init, { run, xdToDiagram } from "wasm-lib";
// let testwasm = async (data: any, setStepGroups: any, setNewData: any) => {
//   console.time("wasm");
//   await init();
//   let diagramGroupList = JSON.parse(xdToDiagram(JSON.stringify(data)));
//   setStepGroups(diagramGroupList);
//   setNewData(false);
//   console.timeEnd("wasm");
//   console.log(diagramGroupList);
// }

export default function XDDiagram(props: any) {
  const [xdId, setXdId] = useState<string|null>(null);
  const [newData, setNewData] = useState(true);
  const [stepGroups, setStepGroups] = useState<DiagramGroup[]>([]);
  const [stepSection, setStepSection] = useState<StepSection>({});
  const [edgeCount, setEdgeCount] = useState(0);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [compact, setCompact] = useState(false);
  const [waitMessage, setWaitMessage] = useState("Preparing Data")

  const worker = useRef<Worker>();

  useEffect(() => {
    if (!worker.current) {
      worker.current = new XDDiagramWorker();
      worker.current.onmessage = async (event: any) => {
        if (event.data.type === "progress") {
          setWaitMessage(`Preparing data: ${event.data.data}`)
        } else if (event.data.type === "result") {
          let diagramGroupList = JSON.parse(event.data.data);
          setStepGroups(diagramGroupList);
          setNewData(false);
        } else if (event.data.type === "resultwasm") {
          console.log("resultwasm");
          let diagramGroupList = JSON.parse(event.data.data);
          setStepGroups(diagramGroupList);
          setNewData(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (xdId !== props.xdId) {
      setXdId(props.xdId);
      setNewData(true);
    }
  }, [props.xdId]);

  useEffect(() => {
    if ((newData || props.xddata?.edges?.length !== edgeCount)
        && props.xddata?.edges?.length > 0) {
      
      prepareData();
    }
  }, [props.xddata]);

  useEffect(() => {
     if (selectedStepId === null)
      setCompact(false)
  }, [selectedStepId]);

  const prepareData = async () => {
    // DownloadDataAsJsonFile(props.xddata, "xddata");
    // console.log(props.xddata);
    // stepSection ------------------------------------------------------------------------
    setEdgeCount(props.xddata?.edges?.length);
    const stepSection_ = getStepSection(props.xddata)
    setStepSection(stepSection_);
    
    // stepGroups ------------------------------------------------------------------------
    if (worker.current) {
      worker.current.postMessage(JSON.stringify(props.xddata));
      // testwasm(props.xddata, setStepGroups, setNewData);
    }
  }

  return(
    <div style={{height: "100%", padding: "5px"}}>
      {newData ?
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        {`${waitMessage}`}
      </div>
      :
      <div style={{display: "flex"}}>
        {stepGroups.map((group: DiagramGroup, groupIndex: number) => 
          <XdDiagramGroup
            key={groupIndex}
            groupIndex={groupIndex}
            groupLength={stepGroups.length}
            group={group}
            stepSection={stepSection}
            steps={props.xddata.steps}
            selectedStepId={selectedStepId}
            setSelectedStepId={setSelectedStepId}
            compact={compact}
            setCompact={setCompact}
          />
        )}
      </div>
      }
    </div>
  )
}

function XdDiagramGroup(
  props: {
    group: DiagramGroup,
    groupIndex: number,
    groupLength: number,
    stepSection: StepSection,
    steps: any,
    selectedStepId: string | null,
    setSelectedStepId: any,
    compact: boolean,
    setCompact: any,
  }
) {
  return (
    <div
      style={{
        marginLeft: props.groupIndex === 0 ? "0" : "5px",
        marginRight: props.groupIndex === (props.groupLength -1) ? "0" : "5px",
      }}>
      <div style={{display: "flex", marginBottom: "10px"}}>
        {props.group.roots.map((stepId: string, stepIndex: number) => {
          let downStream = props.selectedStepId && 
                          (props.steps?.[props.selectedStepId]?.dependOn || [])
                          .concat(props.steps?.[props.selectedStepId]?.dependOnOptional)
                          .includes(stepId);
          let upstream =  props.selectedStepId && 
                          (props.steps?.[stepId]?.dependOn || [])
                          .concat(props.steps?.[stepId]?.dependOnOptional || [])
                          .includes(props.selectedStepId)
          return(
            <div 
              key={stepIndex} 
              style={{
                marginLeft: stepIndex === 0 ? "0" : "5px",
                marginRight: stepIndex === (props.group.roots?.length -1) ? "0" : "5px",
                display: props.selectedStepId == null ? "flex" 
                          : (props.selectedStepId === stepId 
                            || downStream || upstream) ? "flex"
                          : !props.compact ? "flex": "none",
                alignItems: "center", justifyContent: "center",
                flex: 1, cursor: "pointer",
                border: props.group.cycle?.length > 0 ? "solid red 1px" : "solid grey 1px", 
                textAlign: "center", borderRadius: "10px",
                padding: "2px",
                backgroundColor: 
                  stepId === props.selectedStepId ? "lightgreen" 
                  : downStream && upstream ? "orange"
                  : downStream ? "pink"
                  : upstream ? "lightblue"
                  : "lightyellow"
              }}
              onClick={(e: any) => 
                props.setSelectedStepId(stepId === props.selectedStepId ? null : stepId)
              }>
              <div>
                {`${props.stepSection[stepId]?.sectionLabel} ${props.steps[stepId]?.description}` 
                + (props.group.cycle?.length > 0 ? ` (${props.group.cycle?.join(", ")})` : "")}
              </div>
              {props.selectedStepId === stepId &&
              <div style={{marginLeft:"5px"}}>
                <Icon
                  name={props.compact ? "expand" : "compress"}
                  inverted color="red" fitted
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.setCompact(!props.compact)
                  }}
                />
              </div>}
            </div>)
        })}
      </div>
      <div style={{display: "flex"}}>
        {props.group.groupList.map((group: DiagramGroup, groupIndex: number) => 
          <XdDiagramGroup
            key={groupIndex}
            group={group}
            groupIndex={groupIndex}
            groupLength={props.group.groupList.length}
            stepSection={props.stepSection}
            steps={props.steps}
            selectedStepId={props.selectedStepId}
            setSelectedStepId={props.setSelectedStepId}
            compact={props.compact}
            setCompact={props.setCompact}
          />)}
      </div>
    </div>
  )
}
